import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TPortal } from '../helpers/types';

@Injectable({
    providedIn: 'root',
})
export class PortalBridgeService {
    private activePortal = new Subject<{ portal: TPortal; id?: string }>();
    private detachPortal = new Subject<{ data?: any }>();
    private customPortalOutletEvent = new Subject<string>();
    private currentActivePortals: TPortal[] = [];
    private portalData = new BehaviorSubject(null);

    private currentActivePortalRef?: TPortal;

    readonly portalData$ = this.portalData.asObservable();

    readonly portal$ = this.activePortal.asObservable();

    readonly afterDetached$ = this.detachPortal.asObservable();

    readonly customPortalOutletEvent$ =
        this.customPortalOutletEvent.asObservable();

    // use it to check if more then one portal is active then close one
    readonly currActivePortals = this.currentActivePortals;

    setActivePortal(portal: TPortal, id?: any, data?: any) {
        if (portal) {
            this.currentActivePortalRef = portal;
            this.currentActivePortals.push(portal);

            this.activePortal.next({ portal, id });
            if (data) {
                this.portalData.next(data);
            }
        }
    }

    setPortalData(data: any) {
        this.portalData.next(data);
    }

    popLastActivePortal() {
        this.currentActivePortals.pop();
    }

    detach(data?: any) {
        if (
            this.currentActivePortalRef &&
            this.currentActivePortalRef.isAttached
        ) {
            this.currentActivePortalRef.detach();
            this.detachPortal.next(data);
            this.portalData.next(null);
        }
    }

    // use for to trigger events back to component hosting the outlet
    // for example: when closing a portal, the component hosting the portal in their own template
    onCustomPortalOutletEvent(event: string) {
        // event name
        this.customPortalOutletEvent.next(event);
    }
}
